<template>
  <div
    class="flex h-6 w-10 rounded-md border-2 border-neutral-40/30 bg-white text-center"
    :class="{ 'lg:h-10 lg:w-16 lg:text-[10px]': variant !== 'small' }"
    :title="title"
  >
    <component
      :is="icon"
      v-if="icon"
      class="m-auto h-5 max-h-[75%] w-7 max-w-[75%]"
    />
    <span
      v-else
      class="m-auto whitespace-pre-line text-[6px] font-medium text-black"
      :class="{ 'lg:text-[10px]': variant !== 'small' }"
      >{{ $t(`basket.${paymentProvider}`) }}</span
    >
  </div>
</template>

<script setup lang="ts">
import { type PaymentProvider, paymentProvidersTyped } from '~/constants/order'

const props = defineProps({
  paymentProvider: {
    type: String as PropType<PaymentProvider>,
    required: true,
    validator: (value: PaymentProvider) =>
      paymentProvidersTyped.includes(value),
  },
  variant: {
    type: String as PropType<'default' | 'small'>,
    default: 'default',
  },
})

const iconPathMapping: {
  [K in PaymentProvider]?: ReturnType<typeof resolveComponent>
} = {
  ratepay_directdebit: resolveComponent('IconPaymentLastschrift'),
  computop_creditcard_visa: resolveComponent('IconPaymentVisa'),
  computop_creditcard_mastercard: resolveComponent('IconPaymentMastercard'),
  computop_creditcard_discover: resolveComponent('IconPaymentDiscover'),
  klarna_paylater: resolveComponent('IconPaymentKlarna'),
  paypal_instant: resolveComponent('IconPaymentPaypal'),
  twint: resolveComponent('IconPaymentTwint'),
  payu: resolveComponent('IconPaymentPayU'),
  blik: resolveComponent('IconPaymentPayU'),
  computop_creditcard_dinersclub: resolveComponent('IconPaymentDinersclub'),
  p24: resolveComponent('IconPaymentP24'),
  cash_on_delivery: resolveComponent('IconPaymentCashOnDelivery'),
}

const icon = iconPathMapping[props.paymentProvider]

const title =
  typeof icon === 'string'
    ? capitalize(icon)
    : icon?.name?.split('IconPayment')?.[1]
</script>
